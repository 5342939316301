import React from "react";
import styled from "styled-components";

const FooterTag = styled.div`
  text-align: center;
  padding: 20px 0;
  color: #fff;
  background: #2a2a2a;
  font-size: 14px;
  line-height: 1;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const Footer = () => {
  return (
    <FooterTag>ⓒ 2022 株式会社八木造園土木</FooterTag>
  );
};

export default Footer;