import React from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../styles/global";
import theme from "../styles/theme";
import Header from "../components/Header";
import Footer from "../components/Footer";
import styled from "styled-components";

const SiteRoot = styled.div`
  min-height: 100vh;
  position: relative;
  padding-bottom: 54px;
`;

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <SiteRoot>
          <Header />
          {children}
          <Footer />
          <GlobalStyle />
        </SiteRoot>
      </ThemeProvider>
    );
  };
};

export default Layout;