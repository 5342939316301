const variables = {
  sizes: {
    maxWidth: "1100px",
  },
  responsive: {
    small: "599px",
    large: "1024px",
  },
};

export default variables;