import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import ContentWrapper from "../components/ContentWrapper";
import svgLogo from "../svg/logo.svg";

const HeaderTag = styled.div`
  width: 100%;
  background: #fff;
  padding: 15px 0;
  position: fixed;
  z-index: 9999;
`;

const HeaderInner = styled.div`
  .logo {
    width: 288px;
    height: 35px;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      width: 230px;
      height: 28.0935px;
    }
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  .navigation-list {
    display: flex;
    align-items: center;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      display: none;
    }
  }
  .navigation-item {
    margin: 0 20px;
    a {
      color: #000;
      font-weight: bold;
      font-size: 1rem;
    }
  }
  .navigation-contact {
    background: linear-gradient(90deg, #5630af, #3067af);
    border-radius: 5px;
    margin: 0 0 0 20px;
    cursor: pointer;
    a {
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      line-height: 1;
      padding: 9px 18px;
      display: flex;
    }
  }
  .header-button {
    position: relative;
    width: 30px;
    height: 33px;
    padding: 0;
    cursor: pointer;
    display: none;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      display: block;
    }
  }
  .header-button-border {
    width: 30px;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background: #363738;
    z-index: 1000;
  }
  .header-button-border-top {
    top: 1px;
  }
  .header-button-border-middle {
    top: 9px;
  }
  .header-button-border-bottom {
    top: 17px;
  }
  .header-button-text {
    font-size: 10px;
    line-height: 1;
    letter-spacing: 2.8px;
    position: absolute;
    left: -4px;
    top: 25px;
    transform: scale(0.8);
    color: #363738;
    z-index: 1000;
  }
  .menu-content {
    width: 80%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 100%;
    background-color: #fff;
    transition: all 0.5s;
    padding: 63px 25px 25px 25px;
    align-content: center;
    align-items: center;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
  }
  #menu-btn-check:checked ~ .menu-content {
    left: 20%;
    box-shadow: 0 6px 15px 0 rgb(0 0 0 / 20%);
  }
  .menu-list {
    align-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    z-index: 0;
    pointer-events: all;
    display: flex;
    position: relative;
  }
  .menu-item {
    align-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0);
    border-bottom: 1px solid #eee;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    z-index: 0;
    pointer-events: all;
    display: flex;
    position: relative;
  }
  .menu-contact {
    background: linear-gradient(90deg, #5630af, #3067af);
    border-radius: 5px;
    margin: 0 0 12px 0;
    cursor: pointer;
    align-content: center;
    align-items: center;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 40px;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    z-index: 0;
    pointer-events: all;
    display: flex;
    position: relative;
    a {
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      line-height: 1;
      display: flex;
      height: 40px;
      text-align: center;
      width: 100%;
      max-width: 100%;
      justify-content: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      overflow: visible;
      word-break: break-word;
      word-wrap: anywhere;
      z-index: 0;
      pointer-events: all;
      flex-wrap: nowrap;
      position: relative;
      flex: none;
    }
  }
  .item-link {
    color: #2b2c30;
    flex: none;
    font-size: 1rem;
    font-weight: 300;
    height: auto;
    line-height: 1.5;
    padding: 12px 0 12px 0;
    text-align: left;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
  }
`;

const Header = () => {
  return (
    <HeaderTag>
      <ContentWrapper>
        <HeaderInner>
          <h1>
            <Link to={`/`}>
              <img src={svgLogo} className="logo" alt="logo" />
            </Link>
          </h1>
          <nav>
            <ul className="navigation-list">
              <li className="navigation-item">
                <Link to={`/company`}>会社概要</Link>
              </li>
              <li className="navigation-item">
                <Link to={`/works`}>施工事例</Link>
              </li>
              <li className="navigation-item">
                <Link to={`/news`}>ニュース</Link>
              </li>
              <li className="navigation-item">
                <a href="https://en-gage.net/yagizouen" target="_blank" rel="noopener noreferrer">採用情報</a>
              </li>
              <button className="navigation-contact">
                <Link to={`/contact`}>お問い合わせ</Link>
              </button>
            </ul>
          </nav>
          <button className="header-button">
            <input type="checkbox" id="menu-btn-check" />
            <label htmlFor="menu-btn-check">
              <span className="header-button-border header-button-border-top"></span>
              <span className="header-button-border header-button-border-middle"></span>
              <span className="header-button-border header-button-border-bottom"></span>
              <span className="header-button-text">MENU</span>
            </label>
            <div className="menu-content">
              <ul className="menu-list">
                <li className="menu-item">
                  <Link to={`/company`} className="item-link">会社概要</Link>
                </li>
                <li className="menu-item">
                  <Link to={`/works`} className="item-link">施工事例</Link>
                </li>
                <li className="menu-item">
                  <Link to={`/news`} className="item-link">ニュース</Link>
                </li>
                <li className="menu-item">
                  <a href="https://en-gage.net/yagizouen" target="_blank" rel="noopener noreferrer" className="item-link">採用情報</a>
                </li>
                <button className="menu-contact">
                  <Link to={`/contact`}>お問い合わせ</Link>
                </button>
              </ul>
            </div>
          </button>
        </HeaderInner>
      </ContentWrapper>
    </HeaderTag>
  );
};

export default Header;